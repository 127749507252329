import brain2 from '../../designs/dist/img/brain2.png'
import React from 'react'
import styled from 'styled-components'

class Loading extends React.Component {
  render() {
    return (
      <Loader>
        <img
          style={{ height: 100, width: 120 }}
          className="logo"
          src={brain2}
          alt="b2"
        />
        <h1>loading...</h1>
      </Loader>
    )
  }
}

const Loader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  background-color: #001d29;

  @webkit-keyframes pulse {
    0%,
    20%,
    40%,
    60%,
    80% {
      transform: scale3d(1, 1, 1);
      -webkit-transform: scale3d(1, 1, 1);
      -ms-transform: scale3d(1, 1, 1);
    }
    10%,
    30%,
    50%,
    70%,
    90% {
      transform: scale3d(1.1, 1.1, 1.1);
      -webkit-transform: scale3d(1.1, 1.1, 1.1);
      -ms-transform: scale3d(1.1, 1.1, 1.1);
    }
  }
  @keyframes pulse {
    0%,
    20%,
    40%,
    60%,
    80% {
      transform: scale3d(1, 1, 1);
      -webkit-transform: scale3d(1, 1, 1);
      -ms-transform: scale3d(1, 1, 1);
    }
    10%,
    30%,
    50%,
    70%,
    90% {
      transform: scale3d(1.1, 1.1, 1.1);
      -webkit-transform: scale3d(1.1, 1.1, 1.1);
      -ms-transform: scale3d(1.1, 1.1, 1.1);
    }
  }
  h1 {
    color: #fff;
  }

  .logo {
    margin-top: 300px;
    margin-left: auto;
    margin-right: auto;
    -webkit-animation-duration: 5s;
    animation-duration: 5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    -webkit-animation-name: pulse;
    animation-name: pulse;
  }
`

export default Loading
