import React from 'react'

import Script from 'react-load-script'
import styled from 'styled-components'

import '../designs/dist/css/bootstrap.min.css'
import '../designs/dist/js/slick/slick.css'
import '../designs/dist/css/main.min.css'

import head from '../designs/dist/img/head.png'
import feepay from '../img/clients/feepay.png'
import audi from '../img/clients/audi.png'
import eib from '../img/clients/eib.png'
import xolvio from '../img/clients/xolvio.png'
import sourcewell from '../img/clients/sourcewell.png'
import dk from '../img/clients/dk.png'

import sImg1 from '../designs/dist/img/s-img1.png'
import sImg2 from '../designs/dist/img/s-img2.png'
import sImg3 from '../designs/dist/img/s-img3.png'
import bImg1 from '../designs/dist/img/b-img1.jpg'
import brain1 from '../designs/dist/img/brain1.png'
import brain2 from '../designs/dist/img/brain2.png'
import brain3 from '../designs/dist/img/brain3.png'
import beforeImg from '../designs/dist/img/before-img.png'
import afterImg from '../designs/dist/img/after-img.png'
import soImg from '../designs/dist/img/so-img.png'
import cImg1 from '../designs/dist/img/c-img1.png'
import cImg2 from '../designs/dist/img/c-img2.png'
import cImg3 from '../designs/dist/img/c-img3.png'
import cImg4 from '../designs/dist/img/c-img4.png'
import kerry from '../img/testimonials/kerry.jpg'
import sam from '../img/testimonials/sam.jpg'
import tim from '../img/testimonials/tim.jpg'
import jerome from '../img/testimonials/jerome.jpg'
import tomasz from '../img/testimonials/tomasz.png'

import andre from '../img/team/and_re.jpg'
import ania from '../img/team/ania.jpg'
import cywil from '../img/team/cywil.jpg'
import daniel from '../img/team/daniel.jpg'
import dawid from '../img/team/dawid.png'
import gozda from '../img/team/gozda.jpg'
import igor from '../img/team/igor.jpg'
import jagoda from '../img/team/jagoda.jpg'
import karo from '../img/team/karo.jpg'
import lolezy from '../img/team/lolezy.jpg'
import marek from '../img/team/marek.png'
import pingwin from '../img/team/pingwin.jpg'
import ponton from '../img/team/ponton.jpg'
import psiemieniuk from '../img/team/psiemieniuk.png'
import sawa from '../img/team/sawa.jpg'
import sowa from '../img/team/sowa.jpg'
import slawomir from '../img/team/sławomir.jpg'
import twarzer from '../img/team/twarzer.jpg'
import wilk from '../img/team/wilk.jpg'

import Layout from '../components/Layout'
import Loading from '../components/_shared/Loading'

class IndexPage extends React.Component {
  // openSource = React.createRef()
  // testimonials = React.createRef()
  // caseStudy = React.createRef()
  // services = React.createRef()
  state = {
    jQueryReady: false,
    bootstrapReady: false,
    slickReady: false,
    scrollYOffset: 0,
    shouldScroll: false,
    menuVisible: false,
    width: 0,
  }

  componentDidMount = () => {
    const sectionName = this.props.location.href.split('#')[1]
    window.addEventListener('resize', this.handleWindowSizeChange)
    this.setState({ width: window.innerWidth })
    if (sectionName && sectionName.length) {
      this.setState({ shouldScroll: false }, () =>
        setTimeout(
          () =>
            window.scrollTo({
              left: 0,
              top: this.getScrollOffset(sectionName) - 70,
              behavior: 'smooth',
            }),
          500
        )
      )
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { jQueryReady, bootstrapReady, slickReady } = this.state
    const sectionName = this.props.location.href.split('#')[1]

    const shouldScrollChanged =
      this.state.shouldScroll &&
      prevState.shouldScroll !== this.state.shouldScroll
    if (
      prevState.menuVisible !== this.state.menuVisible &&
      !this.state.shouldScroll
    ) {
      return
    }
    if (
      jQueryReady &&
      bootstrapReady &&
      slickReady &&
      sectionName &&
      sectionName.length &&
      this[sectionName]
    ) {
      setTimeout(
        () =>
          window.scrollTo({
            left: 0,
            top: this.getScrollOffset(sectionName) - 70,
            behavior: 'smooth',
          }),
        100
      )
    }

    if (shouldScrollChanged) {
      this.setState({ shouldScroll: false }, () =>
        setTimeout(
          () =>
            window.scrollTo({
              left: 0,
              top: this.state.scrollYOffset,
              behavior: 'smooth',
            }),
          100
        )
      )
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange)
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth })
  }

  toggleMenu = () => {
    this.setState({ menuVisible: !this.state.menuVisible })
  }

  getScrollOffset = refName => {
    console.log('_______ refName', refName)
    let scrollYOffset =
      this[refName] && this[refName].current && this[refName].current.offsetTop
    console.log('_______ scrollYOffset', scrollYOffset)
    if (!scrollYOffset) {
      const element = document.getElementById(refName.replace('#', '')) || {}
      console.log('_______ element', element)
      scrollYOffset = element.offsetTop
      console.log('____xxx___ scrollYOffset', scrollYOffset)
    }
    return scrollYOffset
  }

  triggerScroll = (refName = '') => {
    const scrollYOffset = this.getScrollOffset(refName)
    return this.setState({
      shouldScroll: true,
      // scrollYOffset: this[refName].current.offsetTop,
      scrollYOffset,
      menuVisible: false,
    })
  }

  render = () => {
    const { jQueryReady, bootstrapReady, slickReady } = this.state
    return (
      <>
        <Script
          url={'/jquery-3-3-1.min.js'}
          onLoad={() => this.setState({ jQueryReady: true })}
        />
        {this.state.jQueryReady && (
          <Script
            url={'/bootstrap.bundle.min.js'}
            onLoad={() => this.setState({ bootstrapReady: true })}
          />
        )}
        {this.state.bootstrapReady && (
          <Script
            url={'/slick/slick.min.js'}
            onLoad={() => this.setState({ slickReady: true })}
          />
        )}
        {this.state.slickReady && <Script url={'/custom.js'} />}
        {!(jQueryReady && bootstrapReady && slickReady) ? (
          <Loading />
        ) : (
          <Layout
            triggerScroll={this.triggerScroll}
            toggleMenu={this.toggleMenu}
            menuVisible={this.state.menuVisible}
            isHome={true}
            location={this.props.location}
          >
            <section className="hero" id={'home'}>
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-5 d-flex justify-content-start pt-5 align-items-start flex-column text-white">
                    <h1>
                      <span className="start">The </span>
                      <span className="start shade">brain</span>
                      <br />
                      <span className="start">software</span>
                      <br />
                      <span className="start">house</span>
                    </h1>
                    <label>
                      BUILDING RELIABLE APPS,
                      <br />
                      FASTER THAN YOU CAN IMAGINE
                    </label>
                    <a
                      className="btn btn-hero"
                      onClick={() => {
                        window.location.hash = 'contact'
                        this.triggerScroll('contact')
                      }}
                    >
                      Learn More
                    </a>
                  </div>
                  <div className="col-12 col-lg-7 head-holder">
                    <div className="plus small plus-1" />
                    <div className="plus big plus-2" />
                    <div className="plus big plus-3" />
                    <div className="plus small plus-4" />
                    <div className="plus small plus-5" />
                    <img src={head} alt="head" />
                  </div>
                </div>
              </div>
            </section>
            <section className="section-works pt-10 pb-10 mb-5">
              <div className="container">
                <div
                  className={`row ${
                    this.state.width < 1200 ? 'justify-content-center' : ''
                  }`}
                >
                  <div className="col-12 col-md-6 section-title blue">
                    <h2 className="h1">
                      <span>They loved</span>
                      <br />
                      <span className="dark-effect">working</span>
                      <br />
                      <span>with us</span>
                    </h2>
                    <label className={'label'}>
                      and we think you will too!
                    </label>
                  </div>

                  <div
                    className={`row ${
                      this.state.width < 1200
                        ? 'col-12 col-lg-12'
                        : 'col-9 col-lg-6'
                    } justify-content-center`}
                  >
                    <div className="col-6 col-lg-4 sponsors-holder d-flex justify-content-center align-items-center">
                      <img className="client-image" src={feepay} alt="t-img1" />
                    </div>
                    <div className="col-6 col-lg-4 sponsors-holder d-flex justify-content-center align-items-center">
                      <img className="client-image" src={audi} alt="t-img2" />
                    </div>
                    <div className="col-6 col-lg-4 sponsors-holder d-flex justify-content-center align-items-center">
                      <img className="client-image" src={eib} alt="t-img3" />
                    </div>
                    <div className="col-6 col-lg-4 sponsors-holder d-flex justify-content-center align-items-center">
                      <img
                        className="client-image"
                        src={sourcewell}
                        alt="t-img5"
                      />
                    </div>
                    <div className="col-6 col-lg-4 sponsors-holder d-flex justify-content-center align-items-center">
                      <img className="client-image" src={xolvio} alt="t-img4" />
                    </div>
                    <div className="col-6 col-lg-4 sponsors-holder d-flex justify-content-center align-items-center">
                      <img className="client-image" src={dk} alt="t-img6" />
                    </div>
                    {/*<div className="col-6 col-md-3 sponsors-holder d-flex justify-content-center align-items-center">*/}
                    {/*<img className="client-image" src={sca} alt="t-img7" />*/}
                    {/*</div>*/}
                    {/*<div className="col-6 col-md-3 sponsors-holder d-flex justify-content-center align-items-center">*/}
                    {/*<img className="client-image" src={gcm} alt="t-img8" />*/}
                    {/*</div>*/}
                  </div>
                </div>
              </div>
            </section>
            <section
              className="section-services"
              ref={this.services}
              id="services"
            >
              <div className="container">
                <div className="row">
                  <div
                    className="col-12 section-title blue"
                    style={{ textAlign: 'center' }}
                  >
                    <h2 className="h1">
                      <span>our services</span>
                    </h2>
                  </div>
                  <div className="col-12 col-md-4 services-holder d-flex justify-content-start align-items-center flex-column">
                    <div className="img-holder">
                      <img src={sImg1} alt="s-img1" />
                    </div>
                    <div className="services pt-0 text-center">
                      <label>Application testing</label>
                      <p>
                        We set up automated testing schemes for every single
                        line of code and stress-test your app with thousands of
                        nodes. Our test routines ensure your users get the most
                        out of your app.
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 services-holder d-flex justify-content-start align-items-center flex-column">
                    <div className="img-holder">
                      <img src={sImg2} alt="s-img2" />
                    </div>
                    <div className="services pt-0 text-center">
                      <label>App creation</label>
                      <p>
                        Just starting out and want to validate your idea?
                        Interested in a functional prototype that will help you
                        get funding? We build apps like this in a matter of
                        weeks.
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 services-holder d-flex justify-content-start align-items-center flex-column">
                    <div className="img-holder">
                      <img src={sImg3} alt="s-img3" />
                    </div>
                    <div className="services pt-0 text-center">
                      <label>Team augmentation</label>
                      <p>
                        Our experience and expertise are with React, Node.js and
                        graphQL. We can teach your team how a test-driven
                        approach improves your workflow and speeds up the
                        development process.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              className="section-testimonials slider-holder"
              ref={this.testimonials}
              id={'testimonials'}
            >
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <h2 className="h1 section-title blue">
                      <span>Testimonials</span>
                    </h2>
                  </div>
                </div>
                <div className="row">
                  <div className="testimonials-slider">
                    <div className="item">
                      <div className=" d-flex justify-content-start align-items-center">
                        <div className="testimonial-image">
                          <img src={kerry} alt="Kerry Bollman" />
                        </div>
                        <div className="dark-holder">
                          <label className="section-title white">
                            Kerry Bollman
                          </label>
                          <p>Product Manager, edSpring</p>
                          <span>TIES</span>
                        </div>
                      </div>
                      <div className="testimonial-holder">
                        <p>
                          They collaborate very well with our local team
                          coordinating efforts and{' '}
                          <strong>
                            leading our work on code quality and automated
                            testing.
                          </strong>{' '}
                          I especially admire their skills in translating
                          between technical matters and the end-user experience,
                          and their ability to develop{' '}
                          <strong>
                            quality code that drives customer delight.
                          </strong>{' '}
                        </p>
                      </div>
                    </div>
                    <div className="item">
                      <div className="d-flex justify-content-start align-items-center">
                        <div className="testimonial-image">
                          <img src={sam} alt="Sam Hatoum" />
                        </div>
                        <div className="dark-holder">
                          <label className="section-title white">
                            Sam Hatoum
                          </label>
                          <p>Founder</p>
                          <span>Xolv.io</span>
                        </div>
                      </div>
                      <div className="testimonial-holder">
                        <p>
                          These guys are very skilled and knowledgeable
                          especially when it comes to{' '}
                          <strong>Test Driven Development</strong> which is our
                          #1 priority at <a href="#">Xolv.io</a>. The code
                          quality they produce is of a very high standard and
                          I’m glad to have them delivering our products and
                          providing support.
                        </p>
                      </div>
                    </div>
                    <div className="item">
                      <div className="d-flex justify-content-start align-items-center">
                        <div className="testimonial-image">
                          <img src={tim} alt="Tim Heckel" />
                        </div>
                        <div className="dark-holder">
                          <label className="section-title white">
                            Tim Heckel
                          </label>
                          <p>Founder/Architect</p>
                          <span>CedarLabs</span>
                        </div>
                      </div>
                      <div className="testimonial-holder">
                        <p>
                          TheBrain Software House is a unique mix of raw talent
                          and innovation: by combining a rigor for learning,
                          constant self-evaluation and improvement techniques,
                          and a passion for first-class testing, this crew
                          consistently{' '}
                          <strong>
                            produces rock-solid software at breakneck speed.
                          </strong>{' '}
                          It's truly phenomenal what they've been able to
                          produce, time and again, even on shoestring budgets.
                        </p>
                      </div>
                    </div>

                    <div className="item">
                      <div className="d-flex justify-content-start align-items-center">
                        <div className="testimonial-image">
                          <img src={jerome} alt="Jerome Figueroa" />
                        </div>
                        <div className="dark-holder">
                          <label className="section-title white">
                            Jerome Figueroa
                          </label>
                          <p>Software Development Manager</p>
                          <span>TIES</span>
                        </div>
                      </div>
                      <div className="testimonial-holder">
                        <p>
                          They have the ability to use the necessary tools and
                          processes which translates to higher velocity.{' '}
                          <strong>
                            Collaborative and very easy to communicate with
                          </strong>{' '}
                          – this means a lot especially considering they are a
                          remote team.
                        </p>
                        <p>I definitely recommend them for development work.</p>
                      </div>
                    </div>

                    <div className="item">
                      <div className="d-flex justify-content-start align-items-center">
                        <div className="testimonial-image">
                          <img src={tomasz} alt="Tomasz Olejnik" />
                        </div>
                        <div className="dark-holder">
                          <label className="section-title white">
                            Tomasz Olejnik
                          </label>
                          <p>Project Manager</p>
                          <span>European Investment Bank</span>
                        </div>
                      </div>
                      <div className="testimonial-holder">
                        <p>
                          During our work with TheBrain Software House the team
                          proved itself to be amazingly versatile and ingenious.
                          Throughout the whole process they kept in mind
                          usefulness of the solution and took care of the best
                          user experience possible, what's more - each part of
                          the project was{' '}
                          <strong>
                            delivered on time and meeting all of the
                            requirements.
                          </strong>{' '}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="the-section">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-6 blue-before d-flex justify-content-start align-items-center">
                    <Brains
                      className="brains-bg"
                      //TODO
                      style={{ backgroundImage: `url(${bImg1})` }}
                    >
                      <img
                        className="brain brain-1 pulse"
                        src={brain1}
                        alt="b1"
                      />
                      <img
                        className="brain brain-2 pulse"
                        src={brain2}
                        alt="b2"
                      />
                      <img
                        className="brain brain-3 pulse"
                        src={brain3}
                        alt="b3"
                      />
                    </Brains>
                  </div>
                  <div className="col-12 col-md-6 ">
                    <h2 className="h1 section-title blue">
                      <span>WHO ARE WE?</span>
                    </h2>
                    <p>
                      <strong>TheBrain Software House</strong> consists of
                      highly skilled and motivated software developers. We have
                      skills covering everything from <strong>devops</strong> to{' '}
                      <strong>
                        automated testing, low and high level programming,
                        backend, frontend
                      </strong>{' '}
                      including <strong>UX/UI</strong> and more.
                    </p>
                    <p>
                      We specialize in <strong>rapid development</strong> of
                      reliable, <strong>well tested</strong> web and mobile
                      applications.
                    </p>
                    <p>
                      We have worked with both big and small clients, startups
                      as well as specialized software companies. We deliver
                      bespoke products tailored to our client’s needs.
                    </p>
                    <p>
                      While we love <strong>React and Node</strong> and modern
                      JavaScript ecosystem is our natural environment, we come
                      from very different, supplemental backgrounds and are
                      willing to take on any kind of programming related job.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 d-flex justify-content-end align-items-center">
                    <a className="btn " href="/team">
                      View More
                    </a>
                  </div>
                </div>
              </div>
            </section>
            <section className="section-who-are-we slider-holder">
              <div className="container">
                <div className="row">
                  <div className="team-slider">
                    <div className="member">
                      <div className="d-flex justify-content-center align-items-end">
                        <div className="member-image">
                          <img src={gozda} alt="Łukasz Gandecki" />
                        </div>
                        <div className="dark-holder">
                          <label className="section-title white">
                            Łukasz Gandecki
                          </label>
                        </div>
                      </div>
                      <p>Founder / Senior Software Developer</p>
                    </div>
                    <div className="member">
                      <div className="d-flex justify-content-center align-items-end">
                        <div className="member-image">
                          <img src={lolezy} alt="Michał Opidowicz" />
                        </div>
                        <div className="dark-holder">
                          <label className="section-title white">
                            Michał Opidowicz
                          </label>
                        </div>
                      </div>
                      <p>Senior Software Developer</p>
                    </div>
                    <div className="member">
                      <div className="d-flex justify-content-center align-items-end">
                        <div className="member-image">
                          <img src={daniel} alt="Daniel Strąk" />
                        </div>
                        <div className="dark-holder">
                          <label className="section-title white">
                            Daniel Strąk
                          </label>
                        </div>
                      </div>
                      <p>Senior Software Developer</p>
                    </div>
                    <div className="member">
                      <div className="d-flex justify-content-center align-items-end">
                        <div className="member-image">
                          <img src={twarzer} alt="Piotr Wójcik" />
                        </div>
                        <div className="dark-holder">
                          <label className="section-title white">
                            Piotr Wójcik
                          </label>
                        </div>
                      </div>
                      <p>Senior Software Developer</p>
                    </div>
                    <div className="member">
                      <div className="d-flex justify-content-center align-items-end">
                        <div className="member-image">
                          <img src={andre} alt="Andżelo Mazurek" />
                        </div>
                        <div className="dark-holder">
                          <label className="section-title white">
                            Andżelo Mazurek
                          </label>
                        </div>
                      </div>
                      <p>Senior Software Developer</p>
                    </div>
                    <div className="member">
                      <div className="d-flex justify-content-center align-items-end">
                        <div className="member-image">
                          <img src={slawomir} alt="Sławomir Borzdyński" />
                        </div>
                        <div className="dark-holder">
                          <label className="section-title white">
                            Sławomir Borzdyński
                          </label>
                        </div>
                      </div>
                      <p>Senior Software Developer</p>
                    </div>
                    <div className="member">
                      <div className="d-flex justify-content-center align-items-end">
                        <div className="member-image">
                          <img src={pingwin} alt="Przemysław Gierski" />
                        </div>
                        <div className="dark-holder">
                          <label className="section-title white">
                            Przemysław Gierski
                          </label>
                        </div>
                      </div>
                      <p>Senior Software Developer</p>
                    </div>
                    <div className="member">
                      <div className="d-flex justify-content-center align-items-end">
                        <div className="member-image">
                          <img src={dawid} alt="Dawid Kisała" />
                        </div>
                        <div className="dark-holder">
                          <label className="section-title white">
                            Dawid Kisała
                          </label>
                        </div>
                      </div>
                      <p>Senior Software Developer</p>
                    </div>
                    <div className="member">
                      <div className="d-flex justify-content-center align-items-end">
                        <div className="member-image">
                          <img src={psiemieniuk} alt="Piotr Siemieniuk" />
                        </div>
                        <div className="dark-holder">
                          <label className="section-title white">
                            Piotr Siemieniuk
                          </label>
                        </div>
                      </div>
                      <p>Software Developer</p>
                    </div>
                    <div className="member">
                      <div className="d-flex justify-content-center align-items-end">
                        <div className="member-image">
                          <img src={cywil} alt="Rafał Cywiński" />
                        </div>
                        <div className="dark-holder">
                          <label className="section-title white">
                            Rafał Cywiński
                          </label>
                        </div>
                      </div>
                      <p>Software Developer</p>
                    </div>
                    <div className="member">
                      <div className="d-flex justify-content-center align-items-end">
                        <div className="member-image">
                          <img src={igor} alt="Igor Pasieczny" />
                        </div>
                        <div className="dark-holder">
                          <label className="section-title white">
                            Igor Pasieczny
                          </label>
                        </div>
                      </div>
                      <p>Software Developer</p>
                    </div>
                    <div className="member">
                      <div className="d-flex justify-content-center align-items-end">
                        <div className="member-image">
                          <img src={wilk} alt="Tomasz Wilczek" />
                        </div>
                        <div className="dark-holder">
                          <label className="section-title white">
                            Tomasz Wilczek
                          </label>
                        </div>
                      </div>
                      <p>Software Developer</p>
                    </div>
                    <div className="member">
                      <div className="d-flex justify-content-center align-items-end">
                        <div className="member-image">
                          <img src={ponton} alt="Tomasz Gierczyński" />
                        </div>
                        <div className="dark-holder">
                          <label className="section-title white">
                            Tomasz Gierczyński
                          </label>
                        </div>
                      </div>
                      <p>Software Developer</p>
                    </div>
                    <div className="member">
                      <div className="d-flex justify-content-center align-items-end">
                        <div className="member-image">
                          <img src={sawa} alt="Mateusz Sawka" />
                        </div>
                        <div className="dark-holder">
                          <label className="section-title white">
                            Mateusz Sawka
                          </label>
                        </div>
                      </div>
                      <p>Software Developer</p>
                    </div>
                    <div className="member">
                      <div className="d-flex justify-content-center align-items-end">
                        <div className="member-image">
                          <img src={sowa} alt="Karol Sowiński" />
                        </div>
                        <div className="dark-holder">
                          <label className="section-title white">
                            Karol Sowiński
                          </label>
                        </div>
                      </div>
                      <p>Content Creator</p>
                    </div>
                    <div className="member">
                      <div className="d-flex justify-content-center align-items-end">
                        <div className="member-image">
                          <img src={karo} alt="Karolina Klępka" />
                        </div>
                        <div className="dark-holder">
                          <label className="section-title white">
                            Karolina Klępka
                          </label>
                        </div>
                      </div>
                      <p>Office Manager</p>
                    </div>
                    <div className="member">
                      <div className="d-flex justify-content-center align-items-end">
                        <div className="member-image">
                          <img src={jagoda} alt="Jagoda Machajek" />
                        </div>
                        <div className="dark-holder">
                          <label className="section-title white">
                            Jagoda Machajek
                          </label>
                        </div>
                      </div>
                      <p>Assistant</p>
                    </div>
                    <div className="member">
                      <div className="d-flex justify-content-center align-items-end">
                        <div className="member-image">
                          <img src={ania} alt="Anna Pawliczuk" />
                        </div>
                        <div className="dark-holder">
                          <label className="section-title white">
                            Anna Pawliczuk
                          </label>
                        </div>
                      </div>
                      <p>QA/Content Creator</p>
                    </div>
                    <div className="member">
                      <div className="d-flex justify-content-center align-items-end">
                        <div className="member-image">
                          <img src={marek} alt="Marek Danelczyk" />
                        </div>
                        <div className="dark-holder">
                          <label className="section-title white">
                            Marek Danelczyk
                          </label>
                        </div>
                      </div>
                      <p>Junior Software Developer</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Layout>
        )}
      </>
    )
  }
}
const Brains = styled.div`
  @-webkit-keyframes pulse {
    from {
      transform: scale3d(1, 1, 1);
    }
    50% {
      transform: scale3d(1.05, 1.05, 1.05);
    }
    to {
      transform: scale3d(1, 1, 1);
    }
  }
  @keyframes pulse {
    from {
      transform: scale3d(1, 1, 1);
    }
    50% {
      transform: scale3d(1.05, 1.05, 1.05);
    }
    to {
      transform: scale3d(1, 1, 1);
    }
  }
  .pulse {
    -webkit-animation-name: pulse;
    animation-name: pulse;
    animation-iteration-count: infinite;
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    animation-fill-mode: both;
  }
`
export default IndexPage
